import {
    type Notification, type NotificationDispatchInformation, NotificationsPermissionStatus
} from '../../shared/shared.interface';

type VapKeyDto = {
        vapPublicKey: string,
        id: string
    };

const config = useRuntimeConfig();

export const useRegisterSubscriber = async() => {
    const userEmail = useUser().value.email;
    if (!userEmail)
        return;

    if (!(await requestPermission()))
        return;

    let isEndPointExist = false;
    const aReadyServiceWorker = await navigator.serviceWorker.ready;
    const subscription = await aReadyServiceWorker.pushManager.getSubscription();

    if (subscription) {
        isEndPointExist = await isUserEndPointExist(
            userEmail,
            subscription.endpoint
        );
        if (!isEndPointExist)
            await subscription.unsubscribe();
    }

    if (isEndPointExist)
        return;

    const response: VapKeyDto | null = await getVAPPublicKey(userEmail);
    if (!response)
        return;

    const subscriber = await aReadyServiceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: response.vapPublicKey
    });

    if (!subscriber.endpoint)
        return;

    const subscriberInfo = subscriber.toJSON();
    updateSubscription(
        response.id,
        subscriber.endpoint,
        subscriberInfo.keys?.p256dh ?? '',
        subscriberInfo.keys?.auth ?? ''
    );
};

export const useUnRegisterSubscriber = async() => {
    const userEmail = useUser().value.email;
    if (!userEmail)
        return;

    const aReadyServiceWorker = await navigator.serviceWorker.ready;
    const subscription = await aReadyServiceWorker.pushManager.getSubscription();

    if (subscription) {
        await removeNotificationEndPoint(
            userEmail,
            subscription.endpoint
        );
        await subscription.unsubscribe();
    }
};

const getUserNotificationData = async(userEmail:string) => {
    const url = `${config.public.apiBase}/notification/email/${userEmail}`;
    const { data: response } = await useFetch<Notification[]>(
        url,
        {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        }
    );
    return response.value;
};

const isUserEndPointExist = async(userEmail: string, endPoint: string) => {
    const userNotifications: Notification[] | null = await getUserNotificationData(userEmail);
    const endpointNotifications = userNotifications?.filter(userNotification => (userNotification.endpoint === endPoint));

    return Boolean(endpointNotifications?.length);
};

const removeNotificationEndPoint = async(userEmail: string, endPoint: string) => {
    const userNotifications: Notification[] | null = await getUserNotificationData(userEmail);
    const endpointNotifications = userNotifications?.filter(userNotification => (userNotification.endpoint === endPoint));

    endpointNotifications?.forEach((endpointNotification) => {
        removeNotification(endpointNotification._id!);
    });
};

const removeNotification = async(id:string) => {
    const url =
    `${config.public.apiBase}/notification/${id}`;
    await useFetch(
        url,
        {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        }
    );
};

const getVAPPublicKey = async(userEmail: string) => {
    const notifUrl =
    `${config.public.apiBase}/notification/vap_public_key/${userEmail}`;
    const { data: vapKeyObject } = await useFetch<VapKeyDto>(
        notifUrl,
        {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        }
    );
    return vapKeyObject.value;
};

const updateSubscription = async(
    userId: string,
    endpoint: string,
    p256dh: string,
    auth: string
) => {
    const subscriberInfo = {
        endpoint,
        p256dh,
        auth
    };

    const urlSubScribe = `${config.public.apiBase}/notification/${userId}`;
    await fetch(
        urlSubScribe,
        {
            method: 'PUT',
            body: JSON.stringify(subscriberInfo),
            headers: {
                'content-type': 'application/json'
            }
        }
    );
};

const requestPermission = async(): Promise<boolean> => {
    let success = false;

    if (!('Notification' in window))
        success = false;
    else if (Notification.permission === NotificationsPermissionStatus.GRANTED)
        success = true;
    else if (Notification.permission !== NotificationsPermissionStatus.DENIED)
        await Notification.requestPermission().then((permission) => {
            if (permission === NotificationsPermissionStatus.GRANTED)
                success = true;
            else if (Notification.permission === NotificationsPermissionStatus.DENIED)
                success = false;
        });

    return success;
};

export const useSendNotification = async(
    fromEmail: string,
    toEmail: string,
    issueStatus: string,
    issueId: string,
    issueAddress: string,
    messageType: string,
    title: string
) => {
    if (!fromEmail || !toEmail)
        return;

    const notifMsgBody = {
        fromEmail,
        toEmail,
        serverUrl: config.public.serverUrl,
        issueId,
        issueAddress,
        issueStatus,
        messageType,
        title
    };

    const urlNotification =
    `${config.public.apiBase}/notification/sendnotification`;
    await fetch(
        urlNotification,
        {
            method: 'POST',
            body: JSON.stringify(notifMsgBody),
            headers: {
                'content-type': 'application/json'
            }
        }
    );
};

export const useSendNotificationToAdmins = async(
    fromEmail: string,
    toEmail: string,
    issueStatus: string,
    issueId: string,
    issueAddress: string,
    reporterName: string,
    messageType: string,
    title:string
) => {
    if (!fromEmail)
        return;

    const notifMsgBody = {
        fromEmail,
        toEmail,
        serverUrl: config.public.serverUrl,
        issueId,
        issueAddress,
        issueStatus,
        reporterName,
        messageType,
        title
    };

    const urlNotification =
    `${config.public.apiBase}/notification/sendnotificationtoadmins`;
    await fetch(
        urlNotification,
        {
            method: 'POST',
            body: JSON.stringify(notifMsgBody),
            headers: {
                'content-type': 'application/json'
            }
        }
    );
};

export const useDispatchNotification = async(notificationDispatchInformation:NotificationDispatchInformation) => {
    const url =
    `${config.public.apiBase}/notification-dispatch/dispatchNotification`;
    await fetch(
        url,
        {
            method: 'POST',
            body: JSON.stringify(notificationDispatchInformation),
            headers: {
                'content-type': 'application/json'
            }
        }
    );
};
